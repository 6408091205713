import React from 'react';
import './Marquee.css'; // Ensure you're using your existing CSS for styling

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee-text">
        Get access to exclusive Telegram bot to create nudes of anyone you want.
        <a href="https://t.me/+eYPOuNm4ZWwzMjA1" target="_blank" rel="noopener noreferrer">
          Join the channel and get started !
        </a> 
        🚀 Also Get access to a Google Drive link containing a collection of 18,000+ leaked private mms videos.
        <a href="https://t.me/+eYPOuNm4ZWwzMjA1" target="_blank" rel="noopener noreferrer">
          Join now for exclusive access! 
        </a>
      </div>
    </div>
  );
};

export default Marquee;

